import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

export const addBusinessData = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().addBusiness, data, config);
};
export const updateBusinessData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateBusiness, data, config);
};

export const createBusinessUsers = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addBusinessUser, data, config);
};

export const fetchBusinessInformation = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().fetchBusinessDetails,
    data,
    config
  );
};

export const inviteBusinessUserDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().inviteBusinessUser, data, config);
};

export const fetchAllHRMangerListPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().pageinatedAllBusinessUserList,
    data,
    config
  );
};

export const enableDisableBusinessUserDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().enableDisableBusinessUser,
    data,
    config
  );
};

export const updateBusinessUserDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateBusinessUser, data, config);
};

export const updateBusinessLogoPhoto = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateBusinessLogo, data, config);
};

export const fetchBusinessesListPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchBusinessesListUrl, data, config);
};

export const fetchBusinessesUsersPaginated = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().fetchBusinessHrManagers,
    data,
    config
  );
};

export const fetchImageLabels = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().imageLabels, data, config);
};

export const fetchImageMask = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().imageMask, data, config);
};

export const fetchRootHistory = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchRootDetails, data, config);
};

export const storeDepartmentBusinessUser = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().addDepartmentBusinessUsers,
    data,
    config
  );
};

export const fetchHistoryWorkflowData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getHistoryWorkflowData, data, config);
};

export const getUserResourceUsageData = (
  data, config = null, axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().userResourceUsageData, data, config);
};

export const getWorkflowActionSpeedData = (
  data, config = null, axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().workflowActionSpeed, data, config);
};
