// React Imports
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineProfile,
} from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import {
  Switch,
  Flex,
  Text,
  Box,
  Button,
  Spinner,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import { getEmailId } from "../../../../utils/localStorageIndex";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../../constants/index";
import InviteBusinessUser from "../InviteBusinessUser/InviteBusinessUser";
import ResponsivePagination from "react-responsive-pagination";

// Services Imports
import {
  fetchAllHRMangerListPaginated,
  enableDisableBusinessUserDetails,
} from "../../../../services/businessServices";
import { resetPassword } from "../../../../services/sharedServices";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

function BusinessUsersListMgmt() {
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [resetLoader, setResetLoader] = useState(false);
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [businessDetails, setBusinessDetails] = useState(null);
  const [switchDisableFlag, setSwitchDisableFlag] = useState(false);

  useEffect(() => {
    fetchBusinessHRMgrsDetails();
  }, []);

  const fetchBusinessHRMgrsDetails = () => {
    isLoading(true);
    let objBody = {
      email: getEmailId(),
      records_per_page: 0,
      page_id: currentPage,
      records_length: 0,
    };
    fetchAllHRMangerListPaginated(objBody)
      .then((res) => {
        isLoading(false);
        if (res.data.length > 0) {
          setNoRecordFlag(false);
          setTotalPages(res.data[0].total_pages);
          setTableData(res.data[0].records);
        } else if (!res.error) {
          setNoRecordFlag(true);
        }
      })
      .catch((err) => {
        isLoading(false);
        setNoRecordFlag(true);
      });
  };

  // Todo:Update Business Details
  const updateBusinessDetails = (data) => {
    onOpen();
    setBusinessDetails(data);
  };

  // Todo:Reset Business Email Password
  const resetBusinessEmailPassword = (data) => {
    setResetLoader(true);
    let obj = {
      email: data?.email,
    };
    resetPassword(obj)
      .then((res) => {
        setResetLoader(false);

        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        if (err) {
          setResetLoader(false);
          toast(toastFunctionToaster("Something Went Wrong!!!!", "error"));
        }
      });
  };

  // Todo:Update Business User Status
  const updateBusinessUserStatus = (data) => {
    setResetLoader(true);
    setSwitchDisableFlag(true);
    let obj = {
      user_uuid: data?.user_uuid,
      disabled: !data?.disabled,
    };
    enableDisableBusinessUserDetails(obj)
      .then((res) => {
        setResetLoader(false);
        setSwitchDisableFlag(false);
        fetchBusinessHRMgrsDetails();
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        if (err) {
          setSwitchDisableFlag(false);
          setResetLoader(false);
          toast(toastFunctionToaster("Something Went Wrong!!!!", "error"));
        }
      });
  };

  function renderHrManagerDataCaller() {
    if (tableData && tableData.length > 0) {
      return tableData.map((data) => (
        <Flex
          color="#fff"
          p="15px"
          borderBottomWidth={"1px"}
          borderBottomColor={greyBgColor}
          cursor={"pointer"}
          alignItems={"center"}
          justifyContent={"space-between"}
          key={data?.user_uuid}
        >
          <Flex alignItems={"center"}>
            <Flex alignItems={"center"}>
              <Avatar
                size="md"
                name={data.first_name ? data.first_name : "-"}
                bg="yellow.500"
                mr="2"
                color={"#000"}
              />
              <Text mb="5px" fontSize={"16px"}>
                {data.first_name ? data.first_name : "-"}
                <Text as="span"> {data.last_name ? data.last_name : "-"}</Text>
              </Text>
            </Flex>
            <Flex alignItems={"center"} ml="2">
              <Box>
                <AiOutlinePhone color={defaultGreyColor} fontSize={"16px"} />
              </Box>
              <Text as="span" ml="2" color={defaultGreyColor}>
                {data.phone_number ? data.phone_number : "-"}
              </Text>
            </Flex>
            <Flex alignItems={"center"} ml="2">
              <Box>
                <AiOutlineMail color={defaultGreyColor} fontSize={"16px"} />
              </Box>
              <Text as="span" ml="2" color={defaultGreyColor}>
                {data.email ? data.email : "-"}
              </Text>
            </Flex>
            <Flex alignItems={"center"} ml="2">
              <Box>
                <AiOutlineProfile color={defaultGreyColor} fontSize={"16px"} />
              </Box>
              <Text as="span" ml="2" color={defaultGreyColor}>
                {data.department_name ? data.department_name : "-"}
              </Text>
            </Flex>
          </Flex>
          <Box>
            {!data?.disabled ? (
              <>
                <Button
                  size="sm"
                  mr="2"
                  colorScheme="green"
                  onClick={() => {
                    updateBusinessDetails(data);
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => {
                    resetBusinessEmailPassword(data);
                  }}
                  isDisabled={resetLoader ? true : false}
                  isLoading={resetLoader ? true : false}
                >
                  Reset Password
                </Button>
              </>
            ) : null}
            {switchDisableFlag ? (
              <Spinner my="2" mt="2" size="sm" />
            ) : (
              <Switch
                colorScheme="red"
                ml="2"
                isChecked={data?.disabled}
                isDisabled={switchDisableFlag}
                onChange={() => {
                  updateBusinessUserStatus(data);
                }}
              />
            )}
          </Box>
        </Flex>
      ));
    } else {
      return (
        <Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
          <Text mb="5px" fontSize={16} color={defaultThemeColor}>
            You must add business details before inviting other business users.
          </Text>
        </Box>
      );
    }
  }

  const refreshMemberData = (value) => {
    if (value === "true") {
      fetchBusinessHRMgrsDetails();
      onClose();
    }
  };

  // Todo:Handle
  const handleCloseData = () => {
    onClose();
    setBusinessDetails(null);
  };

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" h="50vh">
          <Spinner size="xl" color="#fff" />
        </Flex>
      ) : noRecordFlag ? (
        <Text padding="10px" color="white">
          No Records Found
        </Text>
      ) : (
        <Box>
          <Box p="15px">
            <Flex>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                color="#fff"
                fontWeight={"600"}
                fontSize="20px"
              >
                Members
              </Box>
              <Button
                bg="white"
                size="sm"
                rightIcon={<FaPlus />}
                onClick={onOpen}
              >
                Invite
              </Button>
            </Flex>
            <Box
              bg={"#151117"}
              borderRadius={"20px"}
              mt="3"
              p="15px"
              h="calc(100vh - 35vh)"
              overflow="auto"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: { greyBgColor },
                  borderRadius: "24px",
                },
              }}
            >
              {renderHrManagerDataCaller()}
            </Box>
            <Box mt="4">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          </Box>
          <Modal
            isOpen={isOpen}
            onClose={() => {
              handleCloseData();
            }}
            size="xl"
          >
            <ModalOverlay />
            <ModalContent bg={greyBgColor} borderRadius="20px">
              <ModalHeader color="#fff">
                {businessDetails?.user_uuid ? "Update" : "Add New"} Team Member
              </ModalHeader>
              <ModalCloseButton color="#d7d7d7" />
              <InviteBusinessUser
                passSuccessFlag={refreshMemberData}
                businessDetails={businessDetails}
              />
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
}

export default BusinessUsersListMgmt;
